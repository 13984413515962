* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased !important;
}

.MuiExpansionPanelSummary-root {
  height: 64px;
  padding-top: 10px;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
  justify-content: space-between;
  margin: 0 !important;
}
