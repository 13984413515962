.MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
  height: 44px !important;
}

.MuiOutlinedInput-input {
  background-color: #FFF !important;
  padding: 10px !important;
  border-radius: 4px !important;
}

.MuiInputBase-formControl {
  border-radius: 4px !important;
}

.MuiInputLabel-outlined {
  font-size: 18px !important;
  top: -9px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(11px, 0px) scale(0.75) !important;
}
